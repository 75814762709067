import CardForm from "../InfoForm/CardInfo";
import blog3 from "../../../Assets/Images/bookName.png";
import { BsTags } from "react-icons/bs";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../SideBar/SideBar";
import PrimaryButton from "../../Atoms/PrimaryButton/PrimaryButton";
import ImgURL from "../../../Utils/ImgUrlGen";
import { CartsDataActions } from "../../../Redux/Slice/Carts";
import { useDispatch, useSelector } from "react-redux";
import { ArrowCircleRight, ArrowLeft, MessageCircle, Messages2, Star, Star1, StarSlash } from "iconsax-react";
import './BooksDetail.scss'
import SecondaryButton from "../../Atoms/SecondaryButton/SecondaryButton";
import { SideBarDataActions } from "Redux/Slice/ToggleSideBar";
import { toast } from "react-toastify";
import { Button, Progress, Rate } from "antd";
import { FaStar } from "react-icons/fa";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { Calendar } from 'react-big-calendar';
import { CheckBooksSold } from "./../../../API/books";
import { GetUserByID } from "./../../../API/users";



const BooksDetail = () => {
  const navigate = new useNavigate();
  const location = new useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFullDescription, setshowFullDescription] = useState(false);

  // To fetch the all books through api
  const GetUserData = async () => {
    const res = await GetUserByID()
    if (res.error != null) {
    } else {

    }
  }
  useEffect(() => {
    GetUserData()
  }, [])



  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const dispatch = useDispatch()
  const cart = useSelector((state) => state?.CartsData)
  const cartData = location?.state?.data

  const reviews = cartData?.reviews || [];
  const totalReviews = reviews.length;

  const valueCounts = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0
  };

  for (let i = 0; i < totalReviews; i++) {
    const value = reviews[i].value;
    valueCounts[value]++;
  }

  let sum = 0;
  for (let i = 0; i < totalReviews; i++) {
    sum += reviews[i].value;
  }

  const averageValue = sum / totalReviews;
  const handleCarts = async () => {

    dispatch(SideBarDataActions?.setSideBarData({ sidemanu: true }))
    if (cartData?.price) {
      if (cartData && cartData.price > 0) {
        // Check if the book is already purchased
        const madrasaUserData = JSON.parse(localStorage.getItem("madrasaUserData"));
        const currentUser = madrasaUserData._id;
        const isPurchased = await CheckBooksSold(currentUser, cartData?._id);

        if (isPurchased?.data?.purchased) {
          toast.warning("Book is already purchased");
          return; // Exit the function if the book is purchased
        }
        let result = []
        if (cart?.length >= 1) {
          await cart?.filter((val) => {
            let bookId = val?._id
            if (bookId === cartData?._id) {
              return result.push(val)
            }
          })
          if (result?.length < 1) {
            dispatch(CartsDataActions?.setCartsData(cartData))
          }
          else {
            return toast.warning("Already In Cart")
          }
        }
        else {
          dispatch(CartsDataActions?.setCartsData(cartData))
        }
      } else {
        return
      }
    }
  }

  const freeBook = () => {
    navigate("/libarary/detail/free-book", { state: { bookData: cartData } });
  };

  const shortenDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };
  const maxDescriptionLength = 255;
  const toggleDescription = () => {
    setshowFullDescription(!showFullDescription);
  };
  return (
    <>
      <section className="booksDetailPage DBlock px-6 sm:px-12 py-48">
        <div className="grid  grid-cols-1 lg:grid-cols-3 gap-1 md:gap-16">
          <div className="w-full h-max group bg-LGrey">
            <div className=" flex justify-center py-6 md:py-10  px-6 md:px-12">
              <img
                className="flex justify-center max-h-96 h-72 md:h-96 bookDetailImg"
                src={ImgURL(cartData?.image)}
                alt="Book"
              />
            </div>
            <div className="p-8 md:p-12  border bg-lightGrey">
              <p className="bookReturn cursor-pointer" onClick={() => { navigate('/library') }}  ><ArrowLeft style={{ cursor: "pointer" }} /> Return to Library</p>
            </div>
          </div>
          <div className="prices col-span-2 mb-2 md:mb-0">
            <div className="xl:max-w-4xl max-w-2xl ">
              <h1 className="bookTitle">
                {cartData?.title}
              </h1>

              <div className="pb-4">
                <p className="publisher">
                  Publisher: {cartData?.publisher}
                </p>
                <p className="author">
                  Author(s): {cartData?.auther?.firstName || "Unknown"} {cartData?.auther?.lastName}
                </p>
                <p className="priceTag">Price: </p>
                <h1 className="price">
                  $
                  {(!cartData?.price || cartData?.price < 1) ? "Free" : cartData?.price}{" "}
                  <span className="pl-2">
                    <BsTags />
                  </span>
                </h1>
              </div>
              <div className="mt-[25px]">
                <Rate className="rate" value={averageValue} />
                <span className="ms-2">{totalReviews}</span>
              </div>
              <p className="detailDescriptionBook">
                {
                  shortenDescription(cartData?.detail?.replace(/<[^>]+>/g, ''), maxDescriptionLength)}
              </p>

              <div className="flex justify-between py-7">
                <div className="flex">
                  {
                    (cartData?.price < 1 || !cartData?.price) &&
                    <PrimaryButton heading="Free Book" primaryFun={freeBook} />
                  }
                  {
                    cartData?.price > 0 &&
                    <div className="secondary-button px-4">
                      <button className="sec-btn" onClick={handleCarts}>
                        Buy Now
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reviewCards">

          <div className="fCard">
            <div className="heading">
              <h1>{averageValue} <FaStar size={42} color=" #FCD117" className="start1" /></h1>
              <p>{totalReviews} Reviews</p>
            </div>
            <div className="reviews">
              <div className="ratesBars">
                <Rate className="numbers" defaultValue={5} character={({ index = 0 }) => index + 1} />
                <Rate className="rates" defaultValue={5} />
                <div className="progress">
                  <Progress
                    className="progressBar"
                    percent={valueCounts[5] > 0 ? 100 : 0}
                    showInfo={false}
                  />
                  <Progress
                    className="progressBar"
                    percent={valueCounts[4] > 0 ? 80 : 0}
                    showInfo={false}
                  />
                  <Progress
                    className="progressBar"
                    percent={valueCounts[3] > 0 ? 60 : 0}
                    showInfo={false}
                  />
                  <Progress
                    className="progressBar"
                    percent={valueCounts[2] > 0 ? 40 : 0}
                    showInfo={false}
                  />
                  <Progress
                    className="progressBar"
                    percent={valueCounts[1] > 0 ? 20 : 0}
                    showInfo={false}
                  />
                </div>



              </div>
              <button className="btn" ><ArrowCircleRight color="#FCD117" size={32} variant="Bold" /> GO TO REVIEWS</button>

            </div>
          </div>


          {/* <div className="lCard">
            <div className="msgLogo">
              <BiSolidMessageRoundedDetail className="logo" variant="Bold" />
            </div>
            <div className="commentDetails">
              <h2>312</h2>
              <p>Commented on this product </p>
              <button className="btn" ><ArrowCircleRight color="#FCD117" size={32} variant="Bold" /> GO TO COMMENT</button>

            </div>
          </div> */}
        </div>
        <div className="allComments">
          <div className="title">
            Reviews
          </div>
          {reviews.map((review, index) => (
            <div className="comment" key={index}>
              <div className="img">
                <img
                  className="flex justify-center max-h-96 h-72 md:h-96 bookDetailImg"
                  src={review.userData.profileImage.url} // Use profile image URL from review's userData
                  alt="Profile"
                />
              </div>
              <div className="details">
                <div className="heading">
                  <h2>{review.userData.firstName} {review.userData.lastName}</h2> {/* Display user's first and last name */}
                  <Rate value={review.value} className="rate" /> {/* Display review rating */}
                </div>
                <div className="para">
                  <p>{review.text}</p> {/* Display review text */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
export default BooksDetail;
