import Carousel from "react-spring-3d-carousel";
import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "./BooksCarasol.scss";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { config } from "react-spring";
import { BsStarFill } from "react-icons/bs";
import { GetAllBooksAPI } from "../../../API/books";
import { toast } from "react-toastify";
import { BooksDataActions } from "../../../Redux/Slice/Books";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import ImgURLGEN from "../../../Utils/ImgUrlGen";
import { BsBag } from 'react-icons/bs';
// import {cardData} from '../../../Helper/commonHelper'





let controllers = {
  goToSlide: 0,
  offsetRadius: 4,
  config: config.gentle,
  showNavigation: false,
};

const BooksCarasol = () => {

  const Dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const gettingAllBooks = async () => {
    setLoading(true)
    const res = await GetAllBooksAPI()
    if (res.error != null) {
      toast.error(res?.error)
    } else {
      let bookData = res?.data?.result || []
      Dispatch(BooksDataActions?.setBooksData(bookData))
    }
    setLoading(false)
  }
  useEffect(() => {
    gettingAllBooks()
  }, [])

  const BooksData = useSelector((state) => state?.BooksData);

  const navigate = useNavigate()
  const handleOneBook = (data) => {
    navigate('/libarary/detail', { state: { data: data } })
  }

  const shortenDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };
  const maxDescriptionLength = 100;

  const cardData = BooksData ? BooksData.map((book, index) => {
    return {
      key: index,
      content: (
        <div className="w-full flex flex-col  justify-between bg-gray-50  py-3 px-2  shadow-md border-2 rounded-lg group">
          <div className="h-56">
            <img
              className="rounded-lg  w-full "
              src={ImgURLGEN(book?.image)}
              alt="portfolio image"
            />
          </div>

          <div className="p-4 item-center text-center">
            <h2 className="title text-2xl font-bold normal-case text-black py-2 cursor-pointer" onClick={() => handleOneBook(book)}>
              {book?.title}
            </h2>
            <ul className="flex items-center justify-center ">
              <li className="font-bold mr-2">5.0</li>
              {[1, 2, 3, 4, 5].map((stars, ind) => (
                <li className="mx-1" key={`starRating${ind}`}>
                  <BsStarFill fill="#FFCC17" />
                </li>
              ))}
            </ul>

            <div className="py-2">
              {/* <a href="/" rel="author"> */}
              <h5 className="text-yellow text-center">
                By :{book?.auther?.firstName} {book?.auther?.lastName}
              </h5>
              {/* </a> */}
            </div>
            <p className="details">
              {shortenDescription(book?.detail?.replace(/<[^>]+>/g, ''), maxDescriptionLength)}
              {/* {book?.detail?.replace(/<[^>]+>/g, '')} */}
            </p>
            {/* <div className="flex-price">
              <div className="price">

                <div className="pricehead">
                  Price:
                </div>
                <div className="pricepara">
                  {(!book?.price || book?.price < 1) ? "Free" : `$ ${book?.price}`}
                </div>
              </div>
              <div className="primary-button2  relative ">
                <div className="absolute z-10 left-4 top-4">
                  <BsBag className='icon' />
                </div>
                <button className="pri2-btn" onClick={() => handleOneBook(book)}>
                  <span className='click'>Detail</span>
                </button>
              </div>
            </div> */}
          </div>
        </div>
      ),
    };
  }) : [];

  const [slideControls, setSlideControls] = useState(controllers);
  let slides = cardData?.map((slide, index) => {
    return {
      ...slide,
      onClick: () => setSlideControls({ goToSlide: index }),
    };
  });

  const mobileSlider = useRef();
  const mobileSlidersettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const gotoNext = () => {
    mobileSlider.current.slickNext();
    setSlideControls({
      ...slideControls,
      goToSlide:
        slides?.length === slideControls?.goToSlide
          ? 0
          : slideControls?.goToSlide + 1,
    });
  };

  const gotoPrev = () => {
    mobileSlider.current.slickPrev();
    setSlideControls({
      ...slideControls,
      goToSlide:
        slideControls?.goToSlide === 0 ? 0 : slideControls?.goToSlide - 1,
    });
  };


  return (
    <section className="cards bg-lightPink DBlock py-10">
      <div className="relative max-w-7xl mx-auto ">
        <div id="styledBrowser" className=" hidden lg:block">
          <Carousel
            slides={slides}
            goToSlide={slideControls.goToSlide}
            offsetRadius={slideControls.offsetRadius}
            showNavigation={slideControls.showNavigation}
            animationConfig={slideControls.config}
          />
        </div>
        <div className="block lg:hidden px-1">
          <Slider  {...mobileSlidersettings} ref={mobileSlider}>
            {cardData.map((list, ind) => (
              <div key={`cards${ind}`}>{list.content}</div>
            ))}
          </Slider>
        </div>
        <div className="flex justify-center items-center pt-28">
          <button
            onClick={gotoPrev}
            className=" hover:text-white  mx-5 bg-gray-200 hover:bg-yellow  flex items-center justify-center w-8 h-8 rounded-full"
          >
            <BsArrowLeftShort size={30} />
          </button>
          <button
            onClick={gotoNext}
            className=" hover:text-white  mx-5 bg-gray-200 hover:bg-yellow  flex items-center justify-center w-8 h-8 rounded-full"
          >
            <BsArrowRightShort size={30} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default BooksCarasol;
