import React from 'react'

import cardImage from '../../../Assets/Images/card-img.jpg'
import PrimaryHeading from '../../../Components/Atoms/PrimaryHeading/PrimaryHeading'

import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';

import './AboutEvents.scss'


const AboutEvents = () => {
    return (
        <div className='events-container'>
            <div className="container">
                <div className="events">
                    <div className="header">
                        <div className="event">Our Events</div>
                        <PrimaryHeading heading="Our Upcoming event" />
                    </div>
                    {/* <div className="cards">
                        <div className="card">
                            <div className="img">
                                <img src={cardImage} alt="" />
                                <div className="badge">
                                    <span>25</span>
                                    <span>Nov</span>
                                </div>
                            </div>
                            <div className="content">
                                <div className="heading">Learn About Hajj</div>
                                <div className="bio">
                                    <div className="time"><AiOutlineClockCircle className='icon' /> 8:00 AM - 5:00 PM</div>
                                    <div className="time"><MdLocationPin /> New York City</div>
                                </div>
                                <div className="para">
                                    learn everything about the pilgrimage, its rituals, and significance in Islam.
                                </div>
                                <div className="link cursor">Learn More...</div>

                            </div>
                        </div>
                        <div className="card">
                            <div className="img">
                                <img src={cardImage} alt="" />
                                <div className="badge">
                                    <span>18</span>
                                    <span>Nov</span>
                                </div>
                            </div>
                            <div className="content">
                                <div className="heading">Learn About Hajj</div>
                                <div className="bio">
                                    <div className="time"><AiOutlineClockCircle className='icon' /> 8:00 AM - 5:00 PM</div>
                                    <div className="time"><MdLocationPin /> New York City</div>
                                </div>
                                <div className="para">
                                    learn everything about the pilgrimage, its rituals, and significance in Islam.
                                </div>
                                <div className="link cursor">Learn More...</div>

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default AboutEvents
