import { GetAllEventsAPI } from "API/events";
import { prayerData } from "../../../Helper/commonHelper";
import "./PrayerBar.scss";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";



const PrayerBar = () => {

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [toggleMarquee, setToggleMarque] = useState(true);

  const gettingAllEvents = async () => {
    setLoading(true)
    const res = await GetAllEventsAPI()
    if (res.error != null) {
      toast.error(res?.error)
    } else {
      let eventData = res?.data?.result || []
      console.log(eventData);
      setEvents(eventData)
    }
    setLoading(false)
  }
  useEffect(() => {
    gettingAllEvents()
  }, [])

  return (
    <section className="PrayerBarContainer fixed bottom-6 w-full z-10">
      <div className="toggleMarquee" onClick={() => setToggleMarque(!toggleMarquee)}> {toggleMarquee ? <FaAngleRight /> : <FaAngleLeft />} </div>
      {
        toggleMarquee &&
        <div className="PrayerBar mx-auto col-span-12 px-6 sm:px-12 py-0 w-full">
          <marquee width="100%" direction="left" height="50px">
            <div className="flex">
              {events?.length >= 1 ?
                <>
                  {events.map((event) => (
                    <div className="flex w-max px-8">
                      <h2 className="font-bold text-xl  flex items-center pr-3">
                        {event?.title}
                      </h2>
                      <div className="w-fit mx-auto flex justify-between py-4">
                        <div className="flex flex-col items-center px-3">
                          <p>{event?.description} </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                :
                <>

                  {prayerData.map((data, ind) => (
                    <div className="flex w-max px-8">
                      <h2 className="font-bold text-xl  flex items-center pr-3">
                        Prayer Times in {data?.cityName}
                      </h2>
                      <div className="max-w-screen-lg mx-auto flex justify-between py-2">
                        <div className="flex flex-col items-center px-3">
                          <h1 className="font-bold">Fajr</h1>
                          <p>{data?.fajarTime} AM</p>
                        </div>
                        <div className="flex flex-col px-3 items-center">
                          <h1 className="font-bold">Zuhr</h1>
                          <p>{data?.zuharTime} PM</p>
                        </div>
                        <div className="flex flex-col items-center px-3">
                          <h1 className="font-bold">Asr</h1>
                          <p>{data?.asarTime} PM</p>
                        </div>
                        <div className="flex flex-col items-center px-2">
                          <h1 className="font-bold">Maghrib</h1>
                          <p>{data?.magribTime} PM</p>
                        </div>
                        <div className="flex flex-col items-center px-2">
                          <h1 className="font-bold">Isha</h1>
                          <p>{data?.ishaTime} PM</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              }
            </div>
          </marquee>
        </div>
      }
    </section>
  );
};

export default PrayerBar;
